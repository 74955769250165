import React, { useState } from 'react'
import { Service, ServiceOverviewModule } from '../lib/sanity-types'
import {
  TextBase,
  css,
  Box,
  Flex,
  Text,
  TransparentButton,
  useSpring,
  animated,
  Container,
  MainHeading,
  useTransition,
  MainHeadingWithDescription
} from '@sitewerk/juraholzbau-design-system'
import { SmallArrowDown } from './icons'
import { useMeasure } from '../lib/hooks'
import { BasicRichText } from './basic-rich-text'
import Img from 'gatsby-image/withIEPolyfill'
import { getFluidGatsbyImage } from 'gatsby-source-sanity'
import * as sanity from '../lib/sanity'
import { FadeInContainer } from './fade-in-container'
import { LearnMoreLink } from './learn-more-link'

const Heading2 = TextBase.withComponent('h3')

export const ServiceOverview: React.FC<
  ServiceOverviewModule & {
    services: Service[]
  }
> = ({ services, text }) => {
  const [activeId, setActiveId] = useState<string | null>(null)

  let currentService = services.find(({ _id }) => _id == activeId)

  if (currentService == null) {
    currentService = services[0]
  }

  const animateImage = useTransition([currentService._id], item => item, {
    from: { opacity: 0, marginTop: 0, position: 'absolute' },
    enter: { opacity: 1 },
    leave: { opacity: 0 }
  })

  const animateArrowProps = currentId =>
    useSpring({
      transform: `rotate(${activeId == currentId ? 180 : 0}deg)`
    })

  const animateDescriptionProps = (currentId, height) =>
    useSpring({
      height: currentId == activeId ? height : 0
    })

  return (
    <Box px={5} mb={7}>
      <Container maxWidth="80rem">
        <MainHeadingWithDescription
          title={text.title}
          description={text.description}
        />
        <FadeInContainer>
          <Flex flexDirection={['column', null, 'row']}>
            <Box
              boxShadow={0}
              width={[1, null, 1 / 2]}
              order={[null, null, 1]}
              ml={[null, null, 7]}
              mb={[3, null, null, 0]}
              position="relative"
              css={{
                minHeight: 400
              }}
            >
              {animateImage.map(({ item, props, key }) => (
                <animated.div
                  key={key}
                  style={{ ...props, width: '100%', height: '100%' }}
                >
                  <Img
                    style={{
                      height: '100%'
                    }}
                    objectFit="cover"
                    objectPosition="50% 30%"
                    fluid={getFluidGatsbyImage(
                      services.find(p => p._id == item).previewImage.asset,
                      { maxWidth: 1920 },
                      sanity.config
                    )}
                  />
                </animated.div>
              ))}
            </Box>

            <Box width={[1, null, 1 / 2]}>
              {[
                services.find(
                  service =>
                    service._id === '9e497c9c-06e3-40d0-92c8-49a6299a7240'
                ),
                ...services.filter(
                  service =>
                    service._id !== '9e497c9c-06e3-40d0-92c8-49a6299a7240'
                )
              ].map(service => {
                const [ref, { height }] = useMeasure<HTMLDivElement>()
                return (
                  <Box key={service._id}>
                    <TransparentButton
                      width={'100%'}
                      px={0}
                      py={2}
                      onClick={() =>
                        activeId == service._id
                          ? setActiveId(null)
                          : setActiveId(service._id)
                      }
                      transitionDuration={0}
                      transitionTimingFunction="ease"
                      css={css({
                        stroke: 'neutral.200',
                        transitionProperty: 'stroke',
                        ':hover': {
                          stroke: 'neutral.400'
                        }
                      })}
                    >
                      <Flex justifyContent="space-between" alignItems="center">
                        <Heading2
                          m={0}
                          fontFamily="default"
                          fontWeight={600}
                          fontSize={3}
                        >
                          {service.name}
                        </Heading2>

                        <animated.div style={animateArrowProps(service._id)}>
                          <SmallArrowDown />
                        </animated.div>
                      </Flex>
                    </TransparentButton>

                    <animated.div
                      style={{
                        overflow: 'hidden',
                        ...animateDescriptionProps(service._id, height)
                      }}
                    >
                      <div ref={ref}>
                        <Box pb={3}>
                          <BasicRichText
                            blocks={service.overviewDescription}
                            TextElement={props => {
                              return (
                                <Text
                                  m={0}
                                  fontSize={1}
                                  fontWeight={300}
                                  lineHeight={2}
                                  {...props}
                                />
                              )
                            }}
                          />

                          <LearnMoreLink
                            mt={2}
                            to={`/dienstleistungen/${service.slug}`}
                          >
                            Mehr erfahren
                          </LearnMoreLink>
                        </Box>
                      </div>
                    </animated.div>
                  </Box>
                )
              })}
            </Box>
          </Flex>
        </FadeInContainer>
      </Container>
    </Box>
  )
}
