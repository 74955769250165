import React, { useEffect, useState } from 'react'
import {
  Box,
  Text,
  Flex,
  SpaceChildren,
  Container,
  theme
} from '@sitewerk/juraholzbau-design-system'
import { Service } from '../lib/sanity-types'
import { SvgInline } from './svg-inline'
import { useConfiguratorContext } from './configurator-context'

export const ConfiguratorFooter: React.FC<{ services: Service[] }> = ({
  services
}) => {
  const { openConfigurator } = useConfiguratorContext()

  return (
    <Box p={5} bg="neutral.700">
      <Container textColor="neutral.200">
        <Flex
          alignItems={['flex-start', null, null, 'center']}
          justifyContent="space-between"
          flexDirection={['column', null, null, 'row']}
        >
          <Box>
            <Text m={0} fontWeight={600} fontSize={0}>
              Projekt starten
            </Text>

            <Text
              m={0}
              mt={2}
              mb={[4, null, null, 0]}
              fontWeight={600}
              fontSize={[5, null, null, 6, 7]}
              maxWidth={['20ch', null, null, null, '15ch']}
            >
              Starten Sie noch heute ein Projekt mit uns.
            </Text>
          </Box>

          <Flex
            justifyContent={[null, null, null, 'flex-end']}
            flexWrap="wrap"
            m={[-2, null, null, 0]}
            mr={[null, null, null, -2]}
            mt={[null, null, null, -2]}
          >
            {services.map(service => (
              <Box
                css={{ cursor: 'pointer' }}
                onClick={() => openConfigurator([service.name])}
                key={service._id}
                p={2}
                width={[1, 1 / 2, 1 / 3, 'inherit']}
              >
                <Flex
                  flexDirection={['row', null, null, 'column']}
                  alignItems={['center', null, null, 'flex-start']}
                  justifyContent={[null, null, null, 'center']}
                  bg="neutral.700"
                  border="1px solid"
                  borderColor="neutral.600"
                  boxShadow={0}
                  css={{
                    transform: 'scale(1)',
                    transition: 'transform .1s ease-out',
                    '&:hover': {
                      transform: 'scale(1.05)'
                    }
                  }}
                  p={[2, null, null, 4]}
                  width={[null, null, null, '12rem']}
                  height={[null, null, null, '14rem']}
                >
                  <Box
                    alignSelf="center"
                    minWidth={['2.5rem', null, null, '4.5rem']}
                    textColor="neutral.500"
                    mb={[0, 0, 0, 4]}
                  >
                    <SvgInline url={service.iconUrl} />
                  </Box>
                  <Text
                    m={0}
                    ml={[3, null, null, 0]}
                    fontWeight={600}
                    fontSize={2}
                  >
                    {service.name}
                  </Text>
                </Flex>
              </Box>
            ))}
          </Flex>
        </Flex>
      </Container>
    </Box>
  )
}
