import { useEffect, useState } from 'react'
import React from 'react'

export const SvgInline = props => {
  const [svg, setSvg] = useState(null)

  useEffect(() => {
    fetch(props.url)
      .then(res => res.text())
      .then(setSvg)
      .catch(console.error)
  }, [props.url])

  return <div dangerouslySetInnerHTML={{ __html: svg }} />
}
